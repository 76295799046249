import { LogDiscord } from "../../utils/LogDiscord";
import {
  addDurationToDate,
  convertToFirebaseTimestamp,
} from "../../utils/datetime";

export const formatApptData = (
  date: string,
  creneau: string,
  duration: string,
  nom: string,
  prenom: string
) => {
  const resDateDebut = convertToFirebaseTimestamp(date, creneau);
  if (!resDateDebut) {
    LogDiscord("resDateDebut is undefined", "testNewAlgorithm.ts");
    throw new Error("resDateDebut is undefined");
  }
  const resDateFin = addDurationToDate(resDateDebut.toDate(), duration);
  const description =
    " pour " + nom + " " + prenom + " le " + date + " à " + creneau;

  return { resDateDebut, resDateFin, description };
};
