import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { FbOrm } from "../utils/fborm";
import { LogDiscord } from "../utils/LogDiscord";
import { sendEmail } from "../utils/mailer";
import { Client } from "../utils/types";

const resCol = collection(db, "appointments");
const dataStr = localStorage.getItem("currentReservation");

const SuccessAppt = () => {
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get("session_id");
  const payment_intent_id = searchParams.get("payment_intent");
  const addedDocId = useRef("");
  const { currentUser } = useAuth();
  const [error, setError] = useState("");

  const handleAddReservation = async () => {
    if (!currentUser || !dataStr) {
      setError("Aucune réservation ou utilisateur non trouvé");
      return;
    }

    const data = JSON.parse(dataStr);
    const { startDate, endDate } = formatTimestamps(data);

    let paymentIntentId = "";

    // 15/05/2024 - 22h22 ANCIEN CODE NON FONCTONNEL, BUG AVEC LES COUPONS.
    /*
     if (data.payment !== "coupon" && (!data.sessionId || !session_id)) {
      if (!payment_intent_id) return;
      paymentIntentId = payment_intent_id;
    } else if (
      data.paymentMethod !== "coupon" &&
      (data.sessionId || session_id)
    ) {
      paymentIntentId = await fetchPaymentIntentId(
        data.sessionId || session_id
      );
    } */

    if (data.payment === "coupon" || data.codeCoupon) {
      paymentIntentId = "COUPON_USED"; // Utiliser un identifiant fictif ou le vrai si nécessaire
    } else {
      // Traitement pour les autres méthodes de paiement nécessitant des détails de session
      if (data.sessionId || session_id) {
        paymentIntentId = await fetchPaymentIntentId(
          data.sessionId || session_id
        );
      } else if (!payment_intent_id) {
        setError(
          "Détails de paiement manquants pour la méthode de paiement spécifiée."
        );
        LogDiscord(
          "Détails de paiement manquants pour la méthode de paiement spécifiée.",
          "SuccessAppt.jsx"
        );
        LogDiscord(
          "```json\n" + JSON.stringify(data) + "```",
          "SuccessAppt.jsx"
        );
        return;
      } else {
        paymentIntentId = payment_intent_id;
      }
    }

    const clientData = await fetchClientData(currentUser.email || "");

    if (!clientData) {
      setError("Client non trouvé pour l'email fourni.");
      return;
    }

    try {
      const reservationData = prepareReservationData(
        data,
        startDate,
        endDate,
        paymentIntentId,
        clientData
      );
      const addedDoc = await addDoc(resCol, reservationData);
      addedDocId.current = addedDoc.id;
      localStorage.removeItem("currentReservation");
      LogDiscord("Réservation ajoutée avec succès", "SuccessAppt.jsx");
      const dataForDiscord =
        "```json\n" + JSON.stringify(reservationData, null, 2) + "\n```";
      LogDiscord(dataForDiscord, "SuccessAppt.jsx");
      sendConfirmationEmails(clientData, reservationData);
    } catch (error: any) {
      console.error("Error adding document: ", error);
      setError(`Erreur lors de l'ajout de la réservation: ${error.message}`);
      LogDiscord(
        `Erreur lors de l'ajout d'un rdv: ${error}`,
        "SuccessAppt.jsx"
      );
    }
  };

  const formatTimestamps = (data: any) => ({
    startDate: new Timestamp(
      data.datedebut.seconds,
      data.datedebut.nanoseconds
    ),
    endDate: new Timestamp(data.datefin.seconds, data.datefin.nanoseconds),
  });

  const fetchPaymentIntentId = async (sessionId: string) => {
    if (!sessionId) return "";
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/retrieve-session?session_id=${sessionId}`
      );
      const session = await response.json();
      return session.payment_intent.id;
    } catch (error) {
      console.error("Error getting payment intent: ", error);
      return "";
    }
  };

  const fetchClientData = async (email: string) => {
    try {
      const clientData = await FbOrm([
        {
          name: "clients",
          where: [{ field: "email", operator: "==", value: email }],
        },
      ]);
      const resClientArr = clientData.clients as Client[];
      return resClientArr[0] || null;
    } catch (error) {
      console.error("Error fetching client data: ", error);
      return null;
    }
  };

  const prepareReservationData = (
    data: any,
    startDate: Timestamp,
    endDate: Timestamp,
    paymentIntentId: string,
    client: Client
  ) => ({
    ...data,
    datedebut: startDate,
    datefin: endDate,
    status: "payed",
    emailReviewSent: false,
    paymentIntentId,
    camp: localStorage.getItem("camp") || "",
    clientId: client.id,
  });

  const sendConfirmationEmails = (client: Client, reservationData: any) => {
    const emailData = {
      toEmail: client.email,
      templateId: "d-345e54ff75f74515a0195f7bd48df89e",
      templateData: {
        title: "Confirmation de votre réservation chez Tadlik",
        subject: "Confirmation de réservation",
        text: `Bonjour ${client.firstname}, votre réservation pour ${reservationData.name} a bien été enregistrée.`,
      },
    };
    sendEmail(emailData);
    const EmailResaDateDebut = reservationData.datedebut
      .toDate()
      .toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    const EmailResaDateFin = reservationData.datefin
      .toDate()
      .toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    const emailDataAdmin = {
      toEmail: "20tadlik@gmail.com",
      templateId: "d-345e54ff75f74515a0195f7bd48df89e",
      templateData: {
        title: "Confirmation de votre réservation chez Tadlik",
        subject: "Confirmation de réservation",
        text: `Bonjour Lakhdar, une réservation à été effectué sur tadlik ! details : ${reservationData.name} pour ${client.lastname} ${client.firstname}, le ${EmailResaDateDebut} au ${EmailResaDateFin} `,
      },
    };
    sendEmail(emailDataAdmin);
  };

  useEffect(() => {
    if (currentUser) {
      handleAddReservation();
    }
  }, [currentUser]);

  return (
    <div className="min-h-[70vh] text-black bg-white">
      {error ? (
        <h1 className="text-4xl text-center pt-20 text-red-500">{error}</h1>
      ) : (
        <>
          <h1 className="text-4xl text-center pt-20">
            Réservation confirmée 🎉
          </h1>
          <p className="text-center mt-4">
            Votre réservation a bien été enregistrée. Vous recevrez un email de
            confirmation dans quelques instants.
          </p>
        </>
      )}
    </div>
  );
};

export default SuccessAppt;
