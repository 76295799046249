import { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { Product as ProductType } from "../utils/types";

type ProductProps = {
  product: ProductType;
};
const Product = ({ product }: ProductProps) => {
  const cart = useContext(CartContext);
  const limitDescription = (description: string) => {
    if (description.length > 300) {
      return description.slice(0, 300) + "...";
    } else {
      return description;
    }
  };
  const desc = limitDescription(product.description);
  return (
    <div
      key={product.id}
      className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
    >
      <div className="aspect-h-4 aspect-w-3 p-4 bg-white sm:aspect-none group-hover:opacity-75 sm:h-64">
        {product.images[0] &&
          product.images[0].url &&
          product.images[0].url !== "" && (
            <img
              src={product.images[0].url}
              className="h-full w-full rounded-md object-cover object-center sm:h-full sm:w-full"
            />
          )}
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm font-medium text-gray-900">
          <Link
            to={"/shop/" + product.id}
            className=" text-black hover:cursor-pointer hover:text-gray-600"
          >
            {product.name}
          </Link>
        </h3>
        <p className="text-sm text-gray-500 text-ellipsis">{desc}</p>
        <div className="flex flex-1 flex-col justify-end">
          <p className="text-base font-medium text-gray-900">
            {product.price} €
          </p>
        </div>
        <button
          className="btn bg-black text-white"
          onClick={() => cart.addOneToCart(product)}
        >
          Ajouter au panier
        </button>
      </div>
    </div>
  );
};

export default Product;
