import ContactSection from "../components/ContactSection";
const ContactPage = () => {
  return (
    <div className="">
      <ContactSection />
      <div className="w-10/12 mx-auto md:flex md:justify-between">
        <div className="w-full md:w-2/5 2xl:w-1/5 md:mx-2 my-8 border-2 border-solid border-black">
          <table className="table text-black">
            <thead>
              <tr>
                <th className="text-black text-lg">Horaires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Lun - Sam : </strong>10:00 - 21:00
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Dim : </strong>10:00 - 19:00
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-full md:w-3/5 my-8 border-2 border-solid border-black">
          <table className="table text-black">
            <thead>
              <tr>
                <th className="text-black text-lg">Contact</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Tel : </strong>07.44.22.88.55
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email : </strong>lazar@tadlik.eu
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Adresse : </strong>35 cours herbouville 69004 lyon
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
