import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import CheckoutForm from "../components/CheckoutForm";
import { useAuth } from "../contexts/AuthContext";
import { FbOrm } from "../utils/fborm";
import { Service as ServiceType } from "../utils/types";
const IMAGES = [
  "https://images.unsplash.com/photo-1519823551278-64ac92734fb1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHwxfHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1600334089648-b0d9d3028eb2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHwyfHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1544161515-4ab6ce6db874?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHwzfHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1515377905703-c4788e51af15?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw0fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1591343395082-e120087004b4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw1fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1519824145371-296894a0daa9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw2fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1611073615830-9f76902c10fe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw3fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1540555700478-4be289fbecef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw4fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1620733723572-11c53f73a416?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHw5fHxtYXNzYWdlfGVufDB8fHx8MTcxMjY5MjE3MXww&ixlib=rb-4.0.3&q=80&w=1080",
  "https://images.unsplash.com/photo-1600334129128-685c5582fd35?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODU1ODR8MHwxfHNlYXJjaHwxMHx8bWFzc2FnZXxlbnwwfHx8fDE3MTI2OTIxNzF8MA&ixlib=rb-4.0.3&q=80&w=1080",
];
// s'inspirer du système de planity pour offrir une prestation
// afficher séléction image et texte pour les cartes cadeaux
// puis passer au paiement, une fois le paiement effectué, envoyer un mail de confirmation avec le pdf et le code cadeau
const Gift = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [prestaPrix, setPrestaPrix] = useState<number | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [personalizedMessage, setPersonalizedMessage] = useState("");
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [step, setStep] = useState(0);
  const [service, setService] = useState<ServiceType | null>();
  const { currentUser } = useAuth();
  const { id, duration, codePromo } = useParams();

  useEffect(() => {
    const pendingGiftcard = JSON.parse(
      localStorage.getItem("pendingGiftcard") || "{}"
    );
    if (pendingGiftcard.image && pendingGiftcard.message) {
      setSelectedImage(pendingGiftcard.image);
      setPersonalizedMessage(pendingGiftcard.message);
      setStep(1);
    }

    getStripePromise();
    if (!id) return;
    const ServiceRef = doc(db, "prestations", id);
    getDoc(ServiceRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setService({ ...docSnap.data(), id: docSnap.id } as ServiceType);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const getStripePromise = async () => {
    setStripePromise(
      await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
    );
  };

  const getUser = async () => {
    if (currentUser) {
      let clientDoc = null;
      clientDoc = await FbOrm([
        {
          name: "clients",
          where: [
            {
              field: "email",
              operator: "==",
              value: currentUser.email,
            },
          ],
        },
      ]);

      if (clientDoc.clients && clientDoc.clients.length === 0) {
        clientDoc = await FbOrm([
          {
            name: "clients",
            where: [
              {
                field: "email",
                operator: "==",
                value: currentUser.email,
              },
            ],
          },
        ]);
      }

      //setClient(clientDoc.clients[0]);
      return clientDoc.clients ? clientDoc.clients : null;
    }
  };
  const selectImage = (img: string) => {
    setSelectedImage(img);
    setStep(1);
  };

  const handlePayment = async () => {
    // 0. on récupère le client

    if (!service) return;

    if (!currentUser) {
      localStorage.setItem(
        "pendingGiftcard",
        JSON.stringify({
          image: selectedImage,
          title: service?.name || "",
          duration: duration,
          message: personalizedMessage,
        })
      );
      navigate("/login?redirect=gift/" + id + "/" + duration);
    }
    const client = await getUser();

    // 1. on récupère le prix de la prestation
    let prix: number;
    if (service && duration) {
      const selectedDuration = service.durations.find(
        (d) => d.dur === duration
      );
      prix = selectedDuration ? parseFloat(selectedDuration.price) : 0;
    } else {
      prix = 0;
    }

    //const clientId = await GetClientIdOrNew();
    if (codePromo && codePromo !== "") {
      const q = query(
        collection(db, "codesPromos"),
        where("code", "==", codePromo)
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const coupon = doc.data();
          prix = prix - (prix * coupon.reduction) / 100;
        });
      }
    }

    // 2. on calcule le prix à payer en fonction de si le client a choisi un paiement en ligne ou un acompte

    // 3. on garde le prix à payer dans le state
    setPrestaPrix(prix);

    // localstorage

    localStorage.setItem(
      "currentgiftcard",
      JSON.stringify({
        image: selectedImage,
        title: service.name,
        duration: duration,
        message: personalizedMessage,
        client: client,
      })
    );

    // 4. on crée une session de paiement avec les infos de la prestation
    const response = await fetch(
      import.meta.env.VITE_API_URL + "/create-checkout-session-gift",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price: prix, // This should be your service amount, make sure to calculate the total cost or deposit
          name: service.name, // Description of the service
          duration: duration, // Include the duration of the service
          personalizedMessage: personalizedMessage,
          // Include any other necessary data
        }),
      }
    );
    const session = await response.json();

    // 6. si le paiement est offline, on garde le client secret dans le state et on affiche le formulaire de paiement de l'accompte
    setClientSecret(session.clientSecret);
    setStep(2);
  };

  const goBack = () => {
    setStep(0);
    setSelectedImage("");
    localStorage.removeItem("currentgiftcard");
    localStorage.removeItem("pendingGiftcard");
    setPersonalizedMessage("");
  };
  return (
    <div className="min-h-[70vh] p-8 text-black">
      <h1 className="font-bold text-2xl">Offrir une prestation</h1>
      {step === 0 && (
        <div className="bg-slate-300 rounded-lg w-full p-4 my-4">
          <p className="text-xl font-bold">
            Choisir une image pour la carte cadeau :
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 my-2">
            {IMAGES.map((img, index) => (
              <div
                key={index}
                className="w-150 h-150 bg-gray-300 rounded-lg overflow-hidden"
                onClick={() => selectImage(img)}
              >
                <img
                  src={img}
                  alt=""
                  className="w-full h-full object-cover transition ease-in-out delay-150 hover:scale-110"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="bg-slate-300 rounded-lg w-full p-4 my-4">
          <p className="text-xl font-bold">Personnaliser la carte cadeau :</p>
          <div className="flex justify-center w-8/12 mx-auto gap-4 my-4">
            <div className="w-150 h-150 bg-gray-300 rounded-lg overflow-hidden">
              <img
                src={selectedImage}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full">
              <textarea
                name=""
                id=""
                cols={30}
                rows={10}
                className="w-full p-2 rounded-lg"
                placeholder="Ajouter un message"
                value={personalizedMessage}
                onChange={(e) => setPersonalizedMessage(e.target.value)}
              ></textarea>
              <br />
              <button className="btn bg-black text-white" onClick={goBack}>
                Précedent
              </button>
              <button
                className="btn bg-black text-white mx-4"
                onClick={handlePayment}
              >
                Payer
              </button>
            </div>
          </div>
        </div>
      )}
      {stripePromise && clientSecret && clientSecret !== "" && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
          }}
        >
          {prestaPrix && (
            <CheckoutForm prestaprice={prestaPrix} isGift={true} />
          )}
        </Elements>
      )}
    </div>
  );
};

export default Gift;
