import { format } from "date-fns";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { FormsAnswers, Formulaire, Question } from "../utils/types";
const Formulaires = () => {
  const { id } = useParams();
  const [form, setForm] = useState<Formulaire | null>(null);
  const [answers, setAnswers] = useState<FormsAnswers | null>(null);
  const [isSubmited, setIsSubmited] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("currentUser", currentUser);
    if (!currentUser) {
      navigate("/login?redirect=forms/" + id);
    }
    const getForm = async () => {
      const formCol = collection(db, "forms");
      const formRef = query(formCol, where("link", "==", id));
      const formSnap = await getDocs(formRef);
      const theform = formSnap.docs[0];
      if (theform.exists()) {
        setForm(theform.data() as Formulaire);
        const initialAnswers = theform
          .data()
          .questions.map((question: Question) => ({
            questionId: question.id,
            questionText: question.question,
            answer: "",
          }));

        const formattedDate = format(new Date(), "dd/MM/yyyy");
        if (!currentUser || !currentUser.email) return;
        setAnswers({
          userId: currentUser.uid,
          email: currentUser.email,
          formName: theform.data().title,
          date: formattedDate,
          questions: initialAnswers,
        });
      } else {
        console.log("No such document!");
      }
    };
    getForm();
  }, [id, currentUser]);
  const handleChange = (questionId: Question["id"], value: string) => {
    //console.log("questionId", questionId, "value", value);
    setAnswers((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        questions: prev.questions.map((q) =>
          q.questionId === questionId ? { ...q, answer: value } : q
        ),
      };
    });
  };

  const handleSubmit = async () => {
    // Ici, vous pouvez ajouter des validations pour les réponses si nécessaire

    const formAnswersRef = collection(db, "formanswers"); // Utilisez un identifiant unique pour chaque soumission si nécessaire
    await addDoc(formAnswersRef, answers);
    console.log("Answers saved:", answers);
    setIsSubmited(true);
    // Vous pouvez ajouter une logique pour rediriger l'utilisateur ou afficher un message de succès
  };
  return (
    <div className=" w-1/2 mx-auto text-black min-h-[70vh]">
      <h1 className="text-2xl font-bold">{form?.title}</h1>
      {!isSubmited &&
        form &&
        answers &&
        form.questions?.map((question, idx) => (
          <div key={question.id} className="mt-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {question.question}
            </label>
            {question.type === "question" && (
              <input
                type="text"
                name={question.id.toString()}
                id={question.id.toString()}
                value={answers.questions[idx]?.answer || ""}
                onChange={(e) => handleChange(question.id, e.target.value)}
                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Votre réponse"
              />
            )}
            {question.type === "question" && (
              <input
                type="hidden"
                name={question.id.toString()}
                id={question.id.toString()}
                value={answers.questions[idx]?.answer || ""}
                onChange={(e) => handleChange(question.id, e.target.value)}
                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Votre réponse"
              />
            )}
            {question.type === "boolean" && (
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => handleChange(question.id, "Oui")}
                  className={`inline-flex items-center shadow-lg border border-slate-100 px-3 py-2 text-sm font-semibold ${
                    answers?.questions[idx]?.answer === "Oui"
                      ? "bg-indigo-600 text-white"
                      : "bg-white text-gray-900"
                  } rounded-l-md focus:z-10`}
                >
                  Oui
                </button>
                <button
                  type="button"
                  onClick={() => handleChange(question.id, "Non")}
                  className={`inline-flex shadow-lg border border-slate-100 items-center px-3 py-2 text-sm font-semibold ${
                    answers.questions[idx]?.answer === "Non"
                      ? "bg-indigo-600 text-white"
                      : "bg-white text-gray-900"
                  } rounded-r-md focus:z-10`}
                >
                  Non
                </button>
              </div>
            )}
          </div>
        ))}

      {!isSubmited && (
        <div className="mt-4">
          <button
            type="button"
            onClick={handleSubmit}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Envoyer
          </button>
        </div>
      )}

      {isSubmited && (
        <div className="mt-4 text-indigo-600 text-3xl font-bold">
          Merci pour votre réponse!
        </div>
      )}
    </div>
  );
};

export default Formulaires;
