import { Link } from "react-router-dom";
import { CategoryType, Service as ServiceType } from "../utils/types";

type ServiceProps = {
  categorie: CategoryType;
  service: ServiceType;
};
const Service = (props: ServiceProps) => {
  const limitDescription = (description: string) => {
    if (description.length > 300) {
      return description.slice(0, 300) + "...";
    } else {
      return description;
    }
  };
  const desc = limitDescription(props.service.description);
  const urlimg = props.service.images?.length
    ? props.service.images[0].url
    : "";

  return (
    <div
      key={props.service.id}
      className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
    >
      <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-64">
        <img
          src={urlimg}
          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm font-medium text-gray-900">
          <Link to={"/services/" + props.service.id} className=" text-black">
            <span aria-hidden="true" className="absolute inset-0" />
            {props.service.name}
          </Link>
        </h3>
        <p className="text-sm text-gray-500 text-ellipsis">{desc}</p>
        <div className="flex flex-1 flex-col justify-end">
          <p className="text-base font-medium text-gray-900">
            à partir de {props.service.durations[0]?.price || "0"} €
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
