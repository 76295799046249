import { WhereFilterOp } from "firebase/firestore";
import { LogDiscord } from "../../utils/LogDiscord";
import { FbOrm } from "../../utils/fborm";

export const getParsedFinalPrice = async (
  initalPrice: number,
  codePromo: string | null,
  paymentMethod: string
) => {
  try {
    const collectionsToFetch = [
      {
        name: "codesPromos",
        where: [
          { field: "code", operator: "==" as WhereFilterOp, value: codePromo },
        ],
      },
    ];

    const fetchedData = await FbOrm(collectionsToFetch);

    // Vérifie si des codes promos ont été trouvés et que le premier a une réduction définie
    if (
      fetchedData.codesPromos &&
      fetchedData.codesPromos.length > 0 &&
      fetchedData.codesPromos.at(0).reduction !== undefined
    ) {
      const reduc = fetchedData.codesPromos.at(0).reduction;
      const priceWithPromo = initalPrice - initalPrice * (reduc / 100);
      const isAdvancePayment = paymentMethod === "offline";
      if (isAdvancePayment) {
        return parseFloat((priceWithPromo * 0.3).toFixed(2));
      } else {
        return parseFloat(priceWithPromo.toFixed(2));
      }
    } else {
      // Aucun code promo valide trouvé
      // Vérifie si le paiement est en ligne ou non
      // Si le paiement est en ligne, retourne le prix initial
      // Si le paiement est sur place "offline", retourne accompte 30% du prix initial
      if (paymentMethod === "offline") {
        return parseFloat((initalPrice * 0.3).toFixed(2));
      } else {
        return initalPrice;
      }
    }
  } catch (error) {
    LogDiscord("error fonction getParsedFinalPrice : " + error, "content.js");
    return initalPrice; // Retourne le prix initial en cas d'erreur
  }
};
