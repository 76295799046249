import { LogDiscord } from "../../utils/LogDiscord";
import { handlePaymentProps } from "../../utils/types";
import { alertNewReservation } from "./alertNewReservation";
import { formatApptData } from "./formatApptData";
import { getParsedFinalPrice } from "./getParsedFinalPrice";
import { getPaymentSession } from "./getPaymentSession";
import { storeLoggedInStorage } from "./storeLoggedInStorage";
import { storeLoggedInStorageCoupon } from "./storeLoggedInStorageCoupon";
import { storeReservationInStorage } from "./storeReservationInStorage";

export const handlePayment = async (props: handlePaymentProps) => {
  // 1. alerte temptative de réservation en cours
  alertNewReservation(props);
  // 2. check si l'utilisateur est connecté
  const isUserLoggedIn = props.currentUser ? true : false;
  // 3. on formate le prix final + applique le code promo s'il existe
  const salePrice = await getParsedFinalPrice(
    props.initialPrice,
    props.codePromo,
    props.paymentMethod
  );

  console.log("salePrice", salePrice);

  props.setSalePrice(salePrice);

  // si l'utilisateur est connecté :
  if (isUserLoggedIn) {
    // 4. formatage des dates de début et de fin + création de la description pour formater les données de la réservation.
    const { resDateDebut, resDateFin, description } = formatApptData(
      props.date,
      props.creneau,
      props.duration,
      props.client.lastname,
      props.client.firstname
    );

    let session = null;

    // 5. on check la methode de paiment
    // si la methode de paiment est offline  ou coupon :
    if (props.paymentMethod === "offline" || props.paymentMethod === "coupon") {
      // 6. on enregistre la réservation dans le localstorage et on redirige vers la page de confirmation
      storeLoggedInStorageCoupon(
        props.service,
        resDateDebut,
        resDateFin,
        props.codecoupon || "1",
        props.paymentMethod,
        props.client
      );
      console.log("code coupon", props.codecoupon);
      window.location.href = "/success-appt";
      return;
    } else if (props.paymentMethod === "online") {
      // sinon si la methode de paiment est online :

      // 7. on créer la session stripe
      session = await getPaymentSession(
        salePrice,
        props.service.name,
        props.paymentMethod,
        description
      );

      console.log("session in handlepayment", session);

      // 8. on enregistre la réservation dans le localstorage
      if (session && session.url) {
        storeLoggedInStorage(
          props.service,
          resDateDebut,
          resDateFin,
          salePrice,
          props.client,
          session,
          props.paymentMethod
        );
        // 9. on redirige vers la page de paiement
        window.location.href = session.url;
      } else {
        // si la session n'est pas créée on affiche une erreur.
        props.setErrors(
          "Une erreur est survenue lors de la réservation, veuillez réessayer dans quelques instants."
        );
        LogDiscord(
          "error fonction getPaymentSession (online pay): " + session,
          "content.js"
        );
      }
    }
  } else {
    // sinon si l'utilisateur n'est pas connecté :

    // 3. on check si le service est défini
    if (!props.service.id) {
      props.setErrors(
        "Une erreur est survenue lors de la réservation, veuillez réessayer dans quelques instants."
      );
      return;
    }
    // 4. on enregistre la réservation dans le localstorage
    storeReservationInStorage(props, salePrice);

    // 5. on redirige vers la page de connexion
    window.location.href = "/login?redirect=paiement";
  }
};
