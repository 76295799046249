import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FbOrm } from "../utils/fborm";

const Hero = () => {
  const [bg, setBg] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  useEffect(() => {
    const getBg = async () => {
      const collections = [
        {
          name: "homePage",
        },
      ];
      const fetcheddata = await FbOrm(collections);
      if (!fetcheddata.homePage) return;
      const data = fetcheddata.homePage.at(0);
      setBg(data.background.url);
      setTitle(data.title);
      setSubtitle(data.subtitle);
    };
    getBg();
  }, []);
  return (
    <div>
      <div
        className={`hero h-[600px] flex justify-center items-center`}
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "rgba(0,0,0,0.5)",
          backgroundPosition: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="hero-content mx-auto flex-col lg:flex-row-reverse h-full">
            <div className="w-10/12">
              <h1 className="text-4xl md:text-5xl font-bold text-white">
                {title}
              </h1>
              <p className="py-6 text-slate-300">{subtitle}</p>
              <Link
                to={"/services"}
                className="btn bg-white text-black hover:bg-black hover:text-white border-none"
              >
                Réserver / Offrir
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
