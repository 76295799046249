import { Timestamp } from "firebase/firestore";
import { LogDiscord } from "./LogDiscord";

// Cette fonction convertit une durée de type chaîne de caractères en heures et minutes.
const parseDuration = (durationStr: string) => {
  durationStr = durationStr.toLowerCase();
  let hours = 0;
  let minutes = 0;
  let minutesStr = "";
  if (durationStr.includes("h")) {
    hours = parseInt(durationStr.split("h")[0]);
    minutesStr = durationStr.split("h")[1];
    if (minutesStr === "") {
      minutes = 0;
    } else {
      minutes = parseInt(minutesStr.split("m")[0]);
    }
  } else if (durationStr.includes("m")) {
    hours = 0;
    minutes = parseInt(durationStr.split("m")[0]);
  }
  return [hours, minutes];
};

// Fonction pour ajouter la durée de la prestation à la date de réservation pour définir la date et heure de fin.
export const addDurationToDate = (startDate: Date, durationStr: string) => {
  //console.log("addDurationToDate", startDate, durationStr);
  const [hours, minutes] = parseDuration(durationStr);
  // console.log("hours", hours, "minutes", minutes);
  const endDate = new Date(startDate.getTime());
  endDate.setHours(endDate.getHours() + hours);
  endDate.setMinutes(endDate.getMinutes() + minutes);
  return endDate;
};

// fonction pour convertir la date et l'heure en timestamp pour firestore
export const convertToFirebaseTimestamp = (
  dateStr: string,
  timeStr: string
) => {
  try {
    const [day, month, year] = dateStr.split("/");
    const isoDateStr = `${year}-${month}-${day}`;

    // Assurez-vous que la date est interprétée dans le bon fuseau horaire
    const dateTimeStr = `${isoDateStr}T${timeStr}`;
    const dateObject = new Date(dateTimeStr);
    return Timestamp.fromDate(dateObject); // Firestore gère automatiquement la conversion en UTC
  } catch (error) {
    console.log("Erreur dans la conversion de la date en timestamp", error);
    LogDiscord(
      "error fonction convertToFirebaseTimestamp : " + error,
      "ReservationPage"
    );
  }
};

export const convertTimestampToString = (timestamp: Timestamp) => {
  return new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  ).toLocaleString();
};
