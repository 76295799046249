import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebase";

export type TAuth = {
  currentUser: User | null;
  signup: (email: string, password: string) => Promise<UserCredential>;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => void;
};

const AuthContext = createContext<TAuth>({
  currentUser: auth.currentUser,
  signup: async () => {
    throw new Error("signup function not initialized");
  },
  login: async () => {
    throw new Error("login function not initialized");
  },
  logout: async () => {},
  resetPassword: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    // Se désabonner de l'écouteur lors du démontage du composant
    return unsubscribe;
  }, []);

  const signup = async (email: string, password: string) => {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    setCurrentUser(result.user);
    return result; // This returns a UserCredential object
  };

  const login = async (email: string, password: string) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    console.log(result);
    setCurrentUser(result.user);
    return result; // This returns a UserCredential object
  };

  const resetPassword = (email: string) => {
    if (email && email !== "") {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log("Email sent");
          alert("Email sent");
        })
        .catch((error: any) => {
          console.log(error);
          alert(error.message);
        });
    } else {
      alert("Please enter an email address");
    }
  };
  const logout = async () => {
    await auth.signOut();
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
