import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import Product from "../components/Product";
import { Product as ProductType } from "../utils/types";

const ShopPage = () => {
  const [products, setProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    const getProducts = () => {
      const productsCol = collection(db, "produits");

      getDocs(productsCol)
        .then((snapshot) => {
          const products: ProductType[] = [];
          snapshot.docs.map((doc) => {
            products.push({ ...doc.data(), id: doc.id } as ProductType);
          });
          setProducts(products);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProducts();
  }, []);

  return (
    <div className="min-h-[70vh]">
      <div className="w-10/12 mx-auto">
        <div className="p-4">
          <h2 className="text-2xl font-bold text-black">Notre Boutique</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-4">
          {products.map((product) => {
            return <Product key={product.id} product={product} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
