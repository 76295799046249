import { LogDiscord } from "../../utils/LogDiscord";
import { handlePaymentProps } from "../../utils/types";

export const storeReservationInStorage = (
  /* serviceId: string,
  duration: string,
  date: string,
  creneau: string,
  pricing: number,
  codePromo: string | null,
  payment: string,
  codecoupon: string | null */
  props: handlePaymentProps,
  salePrice: number
) => {
  try {
    const reservationDetails = {
      serviceId: props.service.id,
      duration: props.duration,
      date: props.date,
      creneau: props.creneau,
      pricing: salePrice,
      codePromo: props.codePromo,
      payment: props.paymentMethod,
      codecoupon: props.codecoupon,
    };

    localStorage.setItem(
      "pendingReservation",
      JSON.stringify(reservationDetails)
    );

    //window.location.href = "/login?redirect=paiement";

    return;
  } catch (error) {
    const errorMessage = [
      "Une erreur est survenue dans la fonction storeReservationInStorage :",
      error,
      " serviceId :",
      props.service.id,
      " duration :",
      props.duration,
      " date :",
      props.date,
      " creneau :",
      props.creneau,
      " pricing :",
      salePrice,
      " codePromo :",
      props.codePromo,
      " payment :",
      props.paymentMethod,
      " codecoupon :",
      props.codecoupon,
    ].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
