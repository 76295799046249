import DOMPurify from "dompurify";
import { doc, getDoc } from "firebase/firestore";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";

const mdTextToHtml = async (text: string) =>
  DOMPurify.sanitize(await marked.parse(text));

type RenderMdProps = {
  text: string;
};
export const RenderMd = ({ text }: RenderMdProps) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      const htmlContent = await mdTextToHtml(text || "");
      setContent(htmlContent);
    };

    fetchContent();
  }, [text]);

  if (!text) return null;

  return (
    <div
      className="content-info"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};
const PagesBlog = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  useEffect(() => {
    const getBlog = async () => {
      if (!id) return;
      const PagesRef = doc(db, "pages", id);
      console.log(id);
      const blogSnap = await getDoc(PagesRef);
      const blogData = blogSnap.data();
      if (!blogData) return;
      setContent(blogData.content);
    };
    getBlog();
  }, [id]);
  return (
    <div className="w-10/12 mx-auto text-black my-10 min-h-[70vh]">
      <RenderMd text={content} />
    </div>
  );
};

export default PagesBlog;
