import ContactSection from "../components/ContactSection";
import Hero from "../components/Hero";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <ContactSection />
      {/* section contact et footer à ajouter et finir composant service */}
    </div>
  );
};

export default HomePage;
