import ky from "ky";
import { useEffect } from "react";

const SuccessGift = () => {
  useEffect(() => {
    const currentGCString = localStorage.getItem("currentgiftcard");
    if (currentGCString) {
      const currentGC = JSON.parse(currentGCString);
      ky.post(import.meta.env.VITE_API_URL + "/success-gift", {
        json: {
          image: currentGC.image,
          title: currentGC.title,
          duration: currentGC.duration,
          message: currentGC.message,
          client: currentGC.client,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          localStorage.removeItem("currentgiftcard");
          localStorage.removeItem("pendingGiftcard");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div className="min-h-[70vh] text-black p-8">
      <h1 className="text-2xl font-bold text-center">
        Merci pour votre achat !
      </h1>
      <p className="text-center">Votre carte cadeau a bien été enregistrée.</p>
      <p className="text-center">
        Vous allez recevoir votre carte cadeau par email.
      </p>
    </div>
  );
};

export default SuccessGift;
