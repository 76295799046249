import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";

const Bandeau = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const fetchMessage = async () => {
      const querySnapshot = await getDocs(collection(db, "messageBandeau"));
      if (querySnapshot.empty) return;
      querySnapshot.forEach((doc) => {
        const messageContent = doc.data().message;
        if (messageContent) {
          // Vérifie si le message n'est pas vide.
          setMessage(messageContent);
        }
      });
    };
    fetchMessage();
    console.log("Bandeau mounted");
    return () => {
      console.log("Bandeau unmounted");
    };
  }, []);

  if (!message) {
    return null;
  }

  return (
    <div className="w-full bg-black text-white font-bold text-center p-2 z-10 sticky top-[64px] xl:top-[68px]">
      <p>{message}</p>
    </div>
  );
};

export default Bandeau;
