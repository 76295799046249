import {
  CollectionReference,
  DocumentData,
  Query,
  WhereFilterOp,
  collection,
  doc,
  query as firebaseQuery,
  getDoc,
  getDocs,
  limit,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

// Définition des types pour les paramètres de requête
type WhereCondition = {
  field: string;
  operator: WhereFilterOp;
  value: any;
};

type OrderByCondition = {
  field: string;
  direction?: "asc" | "desc";
};

type CollectionToFetch = {
  name: string;
  id?: string | string[];
  where?: WhereCondition[];
  orderBy?: OrderByCondition[];
  limit?: number;
};

// Type pour le retour de la fonction
type FetchedData = {
  [key: string]: any;
};

export const FbOrm = async (
  collectionsToFetchArr: CollectionToFetch[]
): Promise<FetchedData> => {
  const data: FetchedData = {};
  for (let collectionInfo of collectionsToFetchArr) {
    const {
      name: collectionName,
      id,
      where: whereConditions,
      orderBy: orderByConditions,
      limit: limitCondition,
    } = collectionInfo;

    if (id) {
      if (Array.isArray(id)) {
        data[collectionName] = [];
        for (let singleId of id) {
          const docRef = doc(db, collectionName, singleId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            (data[collectionName] as (DocumentData | null)[]).push({
              ...docSnap.data(),
              id: docSnap.id,
            });
          } else {
            (data[collectionName] as (DocumentData | null)[]).push(null);
          }
        }
      } else if (typeof id === "string") {
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          data[collectionName] = { ...docSnap.data(), id: docSnap.id };
        } else {
          data[collectionName] = null;
        }
      }
    } else {
      let q: Query<DocumentData> | CollectionReference<DocumentData> =
        collection(db, collectionName);
      if (whereConditions) {
        whereConditions.forEach((condition) => {
          q = firebaseQuery(
            q,
            where(condition.field, condition.operator, condition.value)
          );
        });
      }
      if (orderByConditions) {
        orderByConditions.forEach((condition) => {
          q = firebaseQuery(
            q,
            orderBy(condition.field, condition.direction ?? "asc")
          );
        });
      }
      if (limitCondition !== undefined) {
        q = firebaseQuery(q, limit(limitCondition));
      }

      const collectionSnap = await getDocs(q as Query<DocumentData>);
      const collectionData = collectionSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      data[collectionName] = collectionData;
    }
  }
  return data;
};
