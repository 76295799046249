import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";

type CheckoutFormProps = {
  prestaprice: number;
  isGift: boolean;
};
const CheckoutForm = ({ prestaprice, isGift }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const returnurl = isGift ? "/success-gift" : "/success-appt";

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${returnurl}`,
      },
    });
    setLoading(false);
    if (error && error.message) {
      setMessage(error.message);
    }
  };
  return (
    <div className="w-10/12 grid grid-cols-6 mx-auto">
      <div className=" col-start-1 col-span-6 lg:col-span-4 lg:col-start-2 xl:col-start-2 shadow-xl p-4 mb-8 rounded">
        <h1 className="text-2xl font-bold mb-4">
          Payer {!isGift && "accompte"} : {prestaprice.toFixed(2)} €
        </h1>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <button
            type="submit"
            disabled={!stripe}
            className="bg-black text-white p-4 m-2 rounded-lg w-full"
          >
            {loading ? "Loading..." : "Payer"}
          </button>
        </form>
        <p className="text-red-500">{message}</p>
      </div>
    </div>
  );
};

export default CheckoutForm;
