import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { LogDiscord } from "../../utils/LogDiscord";
import { Client, Service, ServiceDuration } from "../../utils/types";
import Calendrier from "../Calendar";
import CheckoutForm from "../CheckoutForm";
import Creneaux from "../Creneaux";
import { handlePayment } from "./content";

const ReservationPage = () => {
  const [searchParams] = useSearchParams();
  const duration = searchParams.get("duration");
  const id = searchParams.get("id");
  const payment = searchParams.get("payment");
  const paymentMethod = payment;
  const codecoupon = searchParams.get("codecoupon");
  const codePromo = searchParams.get("codePromo");

  const { currentUser } = useAuth();
  //const navigate = useNavigate();
  // get service from json data by categorie (object keys) and id
  const serviceId = id;
  const [service, setService] = useState<Service>();
  const [client, setClient] = useState<Client>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    adress: "",
    city: "",
    zipcode: "",
    country: "",
    notes: "",
    birthdate: "",
    emailPromotions: false,
    smsReminders: false,
    status: "",
    uid: "",
  });
  const [step, setStep] = useState(1);
  const [date, setDate] = useState("");
  const [creneau, setCreneau] = useState("");
  const [initialPrice, setInitialPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  // ^?
  const [errors, setErrors] = useState("");
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  console.log("currentUser", currentUser);

  useEffect(() => {
    // 1. on récupère la prestation que le client veut réserver
    const getService = async () => {
      // ref de la prestation spécifique basée sur l'ID
      if (!serviceId) return;
      const docRef = doc(db, "prestations", serviceId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setService({ ...docSnap.data(), id: docSnap.id } as Service);
          setInitialPrice(
            docSnap
              .data()
              .durations.find((d: ServiceDuration) => d.dur === duration).price
          );
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
        LogDiscord("erreur fonction getService : " + error, "ReservationPage");
      }
    };

    getService();

    // 2. on charge stripe avec la clé publique
    LoadStripeObject();
  }, [serviceId, duration]); // Dépendance uniquement à serviceId

  const LoadStripeObject = async () => {
    try {
      const stripePromiseObject = await loadStripe(
        import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
      );
      setStripePromise(stripePromiseObject);
    } catch (error) {
      console.log(
        "impossible de charger stripe lors d'une reservation :",
        error
      );
      LogDiscord("erreur fonction loadStripe : " + error, "ReservationPage");
    }
  };

  // fonction pour récupérer l'ID du client ou créer un nouveau client

  const handlePaymentClick = () => {
    if (
      !initialPrice ||
      !paymentMethod ||
      !service ||
      !client ||
      !duration ||
      !date ||
      !creneau
    )
      return console.error(
        "missing arguments: initialPrice : ",
        initialPrice,
        "paymentMethod : ",
        paymentMethod,
        "service : ",
        service,
        "client : ",
        client,
        "currentUser : ",
        currentUser,
        "duration : ",
        duration,
        "date : ",
        date,
        "creneau : ",
        creneau
      );
    handlePayment({
      initialPrice,
      codePromo,
      paymentMethod,
      service,
      client,
      currentUser,
      duration,
      date,
      creneau,
      codecoupon,
      setStep,
      setClientSecret,
      setSalePrice,
      setErrors,
    });
  };
  // TODO: add a context maybe ?

  useEffect(() => {
    if (currentUser) {
      // fonction pour récupérer les infos du client
      const getClient = () => {
        // ref de la collection clients
        try {
          const clientsCol = collection(db, "clients");
          if (!currentUser) return;
          const q = query(clientsCol, where("email", "==", currentUser.email));
          getDocs(q).then((snapshot) => {
            const clients = [] as Client[];
            snapshot.docs.map((doc) => {
              clients.push({ ...doc.data(), id: doc.id } as Client);
            });
            console.log("client", clients[0]);
            if (clients[0] === undefined) {
              console.log(currentUser.email);
            }
            setClient(clients[0]);
          });
        } catch (error) {
          console.log(error);
          LogDiscord(
            "erreur fonction getClient : " +
              error +
              "pour " +
              currentUser?.email,
            "ReservationPage"
          );
        }
      };
      getClient();
    }
  }, [currentUser]);

  if (!service || !serviceId || !duration)
    return <div className="text-black mb-8 min-h-[70vh]">loading...</div>;
  return (
    <div className="text-black mb-8 min-h-[70vh]">
      <div className="service-reserved my-2">
        <div className="bg-black text-white w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 rounded-md">
          <h2 className="text-2xl font-bold">{service.name}</h2>
          <p className="text-xl">{duration}</p>
        </div>
      </div>

      <p className="text-center font-bold text-red-500">{errors}</p>

      <div className="text-center my-4">
        <ul className="steps text-center">
          <li className={`step ${step >= 1 ? "step-primary" : ""}`}>
            Choisir une date
          </li>
          <li className={`step ${step >= 2 ? "step-primary" : ""}`}>
            Choisir un créneau
          </li>
          <li className={`step ${step >= 3 ? "step-primary" : ""}`}>
            Réserver
          </li>
        </ul>
      </div>

      {step === 1 && (
        <>
          <Calendrier setdate={setDate} idpresta={serviceId || ""} />
          <div className="actions w-10/12 md:w-[450px] mx-auto flex justify-end">
            <button
              onClick={() => {
                if (!date) {
                  alert("Veuillez choisir une date");
                } else {
                  setStep(2);
                }
              }}
              className="btn bg-black text-white hover:bg-slate-800"
            >
              Suivant
            </button>
          </div>
        </>
      )}
      {step === 2 && (
        <div className="bg-white">
          <Creneaux
            selectedcreneau={creneau}
            setcreneau={setCreneau}
            duree={duration}
            idpresta={serviceId}
            selectedDate={date}
          />
          <div className="actions sticky md:relative bottom-0 bg-white">
            <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto flex justify-between pt-4">
              <button
                onClick={() => setStep(1)}
                className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
              >
                Précédent
              </button>
              <button
                onClick={() => {
                  if (!creneau) {
                    alert("Veuillez choisir un créneau");
                    return;
                  }
                  handlePaymentClick();
                }}
                className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="bg-white">
          {paymentMethod === "offline" &&
            stripePromise &&
            clientSecret &&
            clientSecret !== "" && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecret,
                }}
              >
                <CheckoutForm prestaprice={salePrice} isGift={false} />
              </Elements>
            )}
          <div className="actions sticky md:relative bottom-0 bg-white">
            <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto flex justify-between pt-4">
              <button
                onClick={() => setStep(2)}
                className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
              >
                Précédent
              </button>
              <button
                onClick={() => {
                  handlePaymentClick();
                }}
                className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 5 && (
        <div>
          <div className="bg-white w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 text-center">
            <h2 className="text-2xl font-bold">Merci !</h2>
            <p className="text-xl">
              Votre réservation a bien été prise en compte,
            </p>
            <p className="text-xl">
              Vous aller recevoir un email de confirmation.
            </p>
          </div>
          <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto p-4 bg-slate-200 rounded-lg">
            <h2 className="text-2xl font-bold">Récapitulatif :</h2>
            <p className="text-xl">
              <strong>{service.name}</strong>
            </p>
            <p className="text-xl">
              durée : <strong>{duration}</strong>
            </p>
            <p className="text-xl">
              le <strong>{date}</strong> à <strong>{creneau}</strong>
            </p>
            <p className="text-xl">
              pour{" "}
              <strong>
                {client.lastname || ""} {client.firstname || ""}
              </strong>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationPage;
