export const sendEmail = async (emailData: {
  toEmail: string;
  templateId: string;
  templateData: {
    title: string;
    subject: string;
    text: string;
  };
}) => {
  try {
    const response = await fetch("https://api.tadlik.eu/send-email-template", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });

    if (response.ok) {
      console.log("Email sent successfully");
    } else {
      console.error("Failed to send email");
    }
  } catch (error) {
    console.error("Failed to send email:", error);
  }
};
