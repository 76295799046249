import { useEffect } from "react";
import { LogDiscord } from "../utils/LogDiscord";

const CancelledAppt = () => {
  useEffect(() => {
    const data = localStorage.getItem("currentReservation");
    const message = ["Réservation annulé :", data].join(" ");
    if (data !== "" && data !== null) {
      LogDiscord(message, "content.js");
    }
    localStorage.removeItem("currentReservation");
  }, []);
  return (
    <div className="text-black mb-8 min-h-[70vh] my-4 text-center">
      <h1 className="text-2xl font-bold mb-4">
        Votre réservation a été annulé
      </h1>
      <p className="text-red-500">N'hésiter pas à revenir nous voir !</p>
    </div>
  );
};

export default CancelledAppt;
