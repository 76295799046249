import { Timestamp } from "firebase/firestore";
import { LogDiscord } from "../../utils/LogDiscord";
import { Client, Service } from "../../utils/types";

export const storeLoggedInStorageCoupon = (
  service: Service,
  resDateDebut: Timestamp,
  resDateFin: Date,
  codecoupon: string,
  paymentMethod: string,
  client: Client
) => {
  try {
    localStorage.setItem(
      "currentReservation",
      JSON.stringify({
        idpresta: service.id,
        datedebut: resDateDebut,
        datefin: Timestamp.fromDate(resDateFin),
        name: service.name,
        description: " pour " + client.lastname + " " + client.firstname,
        clientId: client.id,
        payment: paymentMethod,
        codeCoupon: codecoupon,
      })
    );
  } catch (error) {
    LogDiscord(
      "error fonction storeLoggedInStorageCoupon : " + error,
      "content.js"
    );
    const errorMessage = [
      "```json",
      " service :",
      JSON.stringify(service),
      " resDateDebut :",
      resDateDebut,
      " resDateFin :",
      resDateFin,
      " client :",
      JSON.stringify(client),
      " codecoupon :",
      codecoupon,
      " paymentMethod :",
      paymentMethod,
      "```",
    ].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
