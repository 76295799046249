import { useForm, ValidationError } from "@formspree/react";

const ContactSection = () => {
  const [state, handleSubmit] = useForm("mgegekvb");
  if (state.succeeded) {
    return (
      <p className="text-center text-black py-4">
        Merci ! <br /> Nous avons bien reçu votre message.
      </p>
    );
  }

  return (
    <div className=" my-6">
      <div className="title w-10/12 mx-auto">
        <h2 className="text-2xl font-bold text-black">Contactez Nous</h2>
      </div>
      <div className="flex flex-col md:flex-row w-10/12 mx-auto">
        <div className="contactform md:w-1/3 m-2">
          <form onSubmit={handleSubmit} className="">
            <label className="form-control">
              <div className="label">
                <span className="label-text">Votre nom</span>
              </div>
              <input
                type="text"
                className="input input-bordered bg-transparent"
                name="name"
                id="name"
                placeholder="Votre nom"
              />
            </label>
            <label className="form-control">
              <div className="label">
                <span className="label-text">Votre email</span>
              </div>
              <input
                id="email"
                type="email"
                className="input input-bordered bg-transparent"
                name="email"
                placeholder="Votre email"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </label>

            <label className="form-control">
              <div className="label">
                <span className="label-text">Votre message</span>
              </div>
              <textarea
                name="message"
                className="textarea textarea-bordered h-24 bg-transparent"
                placeholder="Votre message"
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </label>

            <button
              type="submit"
              disabled={state.submitting}
              className="btn bg-black text-white my-4"
            >
              Envoyer
            </button>
          </form>
        </div>
        <div className="map md:w-2/3 m-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.641168489482!2d4.8379018767638655!3d45.77838111242053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ebd27042ac1d%3A0xce146482ae24779!2stadlik!5e0!3m2!1sfr!2sfr!4v1702160981314!5m2!1sfr!2sfr"
            width="100%"
            height="450"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
