import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import Service from "../components/Service";
import { CategoryType, Service as ServiceType, UserType } from "../utils/types";

type AdminType = {
  password?: string;
} & UserType;
const ServicePage = () => {
  const [selectedPerson, setSelectedPerson] = useState<string | null>(null);
  const [people, setPeople] = useState<AdminType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [services, setServices] = useState<ServiceType[]>([]);
  const servicesRef = useRef(null);

  const selectedPersonStyle = "bg-gray-200";

  const getServices = (id: string) => {
    console.log("getServices", id);
    // ref de la collection services
    const servicesCol = collection(db, "prestations");
    const categoriesCol = collection(db, "categories");

    // requête pour récupérer les catégories de la personne sélectionnée

    const qcategories = query(
      categoriesCol,
      where("id_prestataire", "==", id),
      orderBy("rang")
    );
    getDocs(qcategories).then((snapshot) => {
      const categories: CategoryType[] = [];
      snapshot.docs.map((doc) => {
        //console.log(doc.data());
        categories.push({ ...doc.data(), id: doc.id } as CategoryType);
      });
      setCategories(categories as CategoryType[]);
      console.log(categories);
    });

    // requête pour récupérer les services de la personne sélectionnée
    const q = query(servicesCol, where("id_prestataire", "==", id));
    getDocs(q)
      .then((snapshot) => {
        const services: ServiceType[] = [];
        snapshot.docs.map((doc) => {
          //console.log(doc.data());
          services.push({ ...doc.data(), id: doc.id } as ServiceType);
        });
        console.log(services);
        setServices(services);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectPerson = (personId: string) => {
    setSelectedPerson(personId);
    getServices(personId);
    if (servicesRef.current) {
      (servicesRef.current as HTMLElement).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const getpeoples = () => {
    setPeople([]);
    const adminsRef = collection(db, "admins");
    const q = query(adminsRef, orderBy("rang"));
    const peoples: AdminType[] = [];
    getDocs(q)
      .then((snapshot) => {
        snapshot.docs.map((doc) => {
          const admin = { ...doc.data(), id: doc.id } as AdminType;
          delete admin.password;
          if (admin.job !== "développeur" && admin.isEnabled) {
            peoples.push(admin as AdminType);
          }
        });
      })
      .then(() => {
        setPeople(peoples);
        setSelectedPerson(peoples[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getpeoples();
  }, []);

  useEffect(() => {
    if (selectedPerson) {
      getServices(selectedPerson);
    }
  }, [selectedPerson]);
  return (
    <div className="overflow-y-auto min-h-[70vh]">
      <div className="bg-white w-10/12 mx-auto">
        {/* Teams */}

        <div className="bg-white pt-6 pb-12 sm:pb-12">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Notre équipe
              </h2>
            </div>
            <ul
              role="list"
              className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
            >
              {people.map((person) => (
                <li
                  key={person.id}
                  className={
                    selectedPerson == person.id
                      ? `${selectedPersonStyle} p-2 rounded-md hover:shadow-md transition duration-300 ease-in-out`
                      : "p-2 rounded-md hover:cursor-pointer border border-gray-200 hover:shadow-md transition duration-300 ease-in-out"
                  }
                  onClick={() => selectPerson(person.id)}
                >
                  {person.imageUrl !== "" ? (
                    <img
                      className="mx-auto h-24 w-24 rounded-full"
                      src={person.imageUrl}
                      alt=""
                    />
                  ) : (
                    <div className="mx-auto h-24 w-24 rounded-full bg-gray-300 flex items-center justify-center">
                      {person.name && (
                        <p className="text-3xl">{person.name[0]}</p>
                      )}
                    </div>
                  )}

                  <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-sm leading-6 text-gray-600">
                    {person.job}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* EndTeams */}

        <div ref={servicesRef} className="p-4">
          {categories.map((categorie) => {
            return (
              <div key={categorie.id}>
                <div className="p-4">
                  <h2 className="text-2xl font-bold text-black">
                    {categorie.nom}
                  </h2>
                </div>
                <div className="services-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                  {services
                    .filter((service) => service.category === categorie.nom)
                    .map((filteredService) => (
                      <Service
                        key={filteredService.id}
                        categorie={categorie}
                        service={filteredService}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
