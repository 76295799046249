import { format, parse } from "date-fns";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { db } from "../../firebase";
import "../App.css";
import { useAuth } from "../contexts/AuthContext";
import { Service } from "../utils/types";
type CalendrierProps = {
  idpresta: string;
  setdate: (date: string) => void;
};
const Calendrier = (props: CalendrierProps) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [blockedDates, setBlockedDates] = useState<Date[]>([]);
  const { currentUser } = useAuth();

  console.log("currentUser", currentUser);
  useEffect(() => {
    const getprestataire = async () => {
      const prestationRef = collection(db, "prestations");
      const q = query(prestationRef, where("id", "==", props.idpresta));
      const snapshot = await getDocs(q);
      let prestation = {} as Service;
      snapshot.docs.map((doc) => {
        prestation = { ...doc.data(), id: doc.id } as Service;
      });
      return prestation.id_prestataire;
    };
    const fetchBlockedDates = async () => {
      const idPrestataire = await getprestataire();
      const offDatesRef = collection(db, "offDates");
      onSnapshot(offDatesRef, (snapshot) => {
        let dates: Date[] = [];
        snapshot.docs.forEach((doc) => {
          const dateStr = doc.data().date; // Assurez-vous que la structure des données est correcte
          if (doc.data().id_prestataire === idPrestataire) {
            dates.push(parse(dateStr, "dd/MM/yyyy", new Date()));
          }
        });
        setBlockedDates(dates);
      });
    };

    fetchBlockedDates();
  }, []);

  const setNewDate = (date: Date) => {
    setSelectedDate(date);
    const dateString = format(date, "dd/MM/yyyy");
    props.setdate(dateString);
  };

  const isDateBlocked = (date: Date) => {
    return blockedDates.some(
      (blockedDate) =>
        format(blockedDate, "dd/MM/yyyy") === format(date, "dd/MM/yyyy")
    );
  };

  return (
    <div>
      <h1 className="text-center text-3xl font-bold mt-10">
        Choisissez une date
      </h1>
      <div className="flex justify-center m-6">
        <Calendar
          onClickDay={(date) => setNewDate(date)}
          value={selectedDate}
          tileDisabled={({ date, view }) =>
            view === "month" && isDateBlocked(date)
          }
        />
      </div>
    </div>
  );
};

export default Calendrier;
